import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';

const UpdateLogo = (props) => {
    const [image, setImage] = React.useState();
    const [prviewUrl, setPreviewUrl] = React.useState();
    const [loadError, setLoadError] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [currentLogo, setCurrentLogo] = React.useState('');

    const team_id = props.team_id;

    const getLogo = async () => {
        try {
            const response = await fetch(` https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-logo/${team_id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const imageBlob = await response.blob();
            const imageUrl = URL.createObjectURL(imageBlob);
            setCurrentLogo(imageUrl);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getLogo();
    }, []);

    useEffect(() => {
        return () => {
            if (prviewUrl) {
                URL.revokeObjectURL(prviewUrl);
            }
        };
    }, [prviewUrl]);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
            setLoadError(false);
        }
    };

    const handleError = () => {
        setLoadError(true);
        setImage(null);
        setPreviewUrl(null);
    };

    const handleReset = () => {
        setImage(null);
        setPreviewUrl(null);
        setLoadError(false);
        setUploading(false);
    };

    const handleUpload = async () => {
        if (!image) {
            console.log('No image selected');
            return;
        }
        setUploading(true);
        const formData = new FormData();
        formData.append('team_id', team_id);
        formData.append('logo', image);
        try {
            const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/upload-logo', {
                method: 'POST',
                body: formData
            });
            if (response.status === 200) {
                console.log('Logo updated successfully');
                handleReset();
                getLogo();
            }
            else {
                console.log('Error updating logo');
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    return (<>
    <Typography variant='h6'>Team Logo</Typography>
    <img style={{ width: '65px', height: '65px', marginRight: '5px', borderRadius: '50%', objectFit: 'contain' }} alt='logo' src={currentLogo || process.env.PUBLIC_URL + '/no-logo.png'} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/no-logo.png' }} />
        <Box sx={{ border: 'black', borderStyle: 'dashed', padding: '10px', m: '10px' }}>
            {uploading ?  (
                <CircularProgress />
            ) : loadError || !prviewUrl ? (
                <input type='file' accept='image/*' onChange={(e) => onDrop(e.target.files)} />
            ) : (
                <img style={{ width: '65px', height: '65px', borderRadius: '50%', objectFit: 'contain' }} alt='logo' src={prviewUrl} onError={handleError} />
            )}

            <Box sx={{ display: 'flex', gap: 2, m: '5px' }}>
                <Button disabled={uploading || !image} variant='outlined' onClick={handleUpload}>Upload</Button>
                <Button disabled={uploading || !image} variant='outlined' onClick={handleReset}>Reset</Button>
            </Box>
        </Box>
   </> );
};

export default UpdateLogo;