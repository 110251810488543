import React from 'react';

const Logo = (props) => {
    const [logo, setLogo] = React.useState('');

    const getLogo = async (team_id) => {
        try {
            const response = await fetch(` https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-logo/${team_id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const imageBlob = await response.blob();
            const imageUrl = URL.createObjectURL(imageBlob);
            setLogo(imageUrl);
        }
        catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        getLogo(props.team_id);
    }, [props.team_id]);


    return (
        <img style={{ width: '65px', height: '65px', marginRight: '5px', borderRadius: '50%', objectFit: 'contain' }} alt='logo' src={logo || process.env.PUBLIC_URL + '/no-logo.png'} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/no-logo.png' }} />
    );
};

export default Logo;