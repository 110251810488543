import React from 'react';
import Header from './Header/Header';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditSchedule from './AdminTiles/EditSchedule';
import EditScore from './AdminTiles/EditScore';
import EditTeams from './AdminTiles/EditTeams';
import EditCodes from './AdminTiles/EditCodes';

const AppAdmin = (props) => {

  const [verified, setVerified] = React.useState(false);
  const [password, setPassword] = React.useState('');

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

    return (
      <Box>
        <Header />
        {!verified && <Box sx={{ margin: '10px', marginTop: '50px' }}>
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="outlined-password-input"
            label="Admin Password"
            type="password"
            sx={{ width: '300px' }}
          />
          <br />
          <Button
            variant="contained"
            onClick={() => {
              if (password === 'JelluShi0128') {
                setVerified(true);
              }
            }}
            sx={{ marginTop: '20px', color: 'white', backgroundColor: 'black' }}
          >
            Verify
          </Button>
        </Box>}

        {verified && <Box sx={{  margin: '10px', marginTop: '20px' }}>

          <Accordion className='menu-item' expanded={expanded === 'edit-schedule'} onChange={handleExpandChange('edit-schedule')}>
            <AccordionSummary id='admin-titles' expandIcon={<ExpandMoreIcon />} sx={ expanded !== 'edit-schedule' ? { backgroundColor: '#ffffff', color: '#000000' } : { backgroundColor: '#000000', color: '#ffffff'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>Edit Schedule</Typography>
              <Typography sx={{ color: 'grey' }}>Add / Remove match schedules</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <EditSchedule gameList={props.gameList} getGames={props.getGames}/>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='menu-item' expanded={expanded === 'edit-scores'} onChange={handleExpandChange('edit-scores')}>
            <AccordionSummary id='admin-titles' expandIcon={<ExpandMoreIcon />} sx={ expanded !== 'edit-scores' ? { backgroundColor: '#ffffff', color: '#000000' } : { backgroundColor: '#000000', color: '#ffffff'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>Edit Score</Typography>
              <Typography sx={{ color: 'grey' }}>Add / Modify team scores</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <EditScore gameList={props.gameList} getGames={props.getGames} teamList={props.teamList} getTeams={props.getTeams} scoreList={props.scoreList} getScores={props.getScores}/>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='menu-item' expanded={expanded === 'edit-teams'} onChange={handleExpandChange('edit-teams')}>
            <AccordionSummary id='admin-titles' expandIcon={<ExpandMoreIcon />} sx={ expanded !== 'edit-teams' ? { backgroundColor: '#ffffff', color: '#000000' } : { backgroundColor: '#000000', color: '#ffffff'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>Edit Teams</Typography>
              <Typography sx={{ color: 'grey' }}>Add / Modify / Remove teams</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <EditTeams teamList={props.teamList} getTeams={props.getTeams} />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className='menu-item' expanded={expanded === 'player-codes'} onChange={handleExpandChange('player-codes')}>
            <AccordionSummary id='admin-titles' expandIcon={<ExpandMoreIcon />} sx={ expanded !== 'player-codes' ? { backgroundColor: '#ffffff', color: '#000000' } : { backgroundColor: '#000000', color: '#ffffff'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>Player Codes</Typography>
              <Typography sx={{ color: 'grey' }}>Generate player codes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <EditCodes codeList={props.codeList} getCodes={props.getCodes} teamList={props.teamList} />
              </Typography>
            </AccordionDetails>
          </Accordion>

        </Box>}
      </Box>
    );

}

export default AppAdmin;
