import { Box, Button, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import React from 'react';

const EditCodes = (props) => {
    const [operation, setOperation] = React.useState(null);
    const [addCode, setAddCode] = React.useState('');
    const [selectedCode, setSelectedCode] = React.useState({});
    const [selectedTeam, setSelectedTeam] = React.useState('');


    const handleAddCode = async() => {
        if (addCode !== '' && selectedTeam !== '') {
            try {
                const response = await fetch('https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/create-code', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        code: addCode,
                        team_id: selectedTeam
                    })
                });
                if (response.status === 200) {
                    setAddCode('');
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const handleDeleteCode = async() => {
        if (selectedCode !== '') {
            try {
                const response = await fetch('https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/delete-codes', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        id: selectedCode
                    })
                });
                if (response.status === 200) {
                    setSelectedCode('');
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    }



    return (
    <Box sx={{ justifyContent: "center", textAlign: 'center' }} >
        <RadioGroup onChange={(e) => setOperation(e.target.value)} value={operation} row sx={{ justifyContent: 'center' }}>
            <FormControlLabel value="add" control={<Radio />} label="Add Code" />
            <FormControlLabel value="delete" control={<Radio />} label="Delete Code" />
        </RadioGroup>

        {operation && <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />}

        {operation === 'add' && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField 
                type='text' 
                label='Code' 
                sx={{ marginRight: '20px', width: '300px', marginTop: '20px' }} 
                value={addCode}
                onChange={(e) => setAddCode(e.target.value)}
            /> <br />
            <Typography variant='subtitle1'>Select a Team</Typography>
            <Select
                labelId="select-team"
                id="select-team"
                value={selectedTeam}
                label="Select Team"
                onChange={(e) => setSelectedTeam(e.target.value)}
            >
                {props.teamList.map((team) => {
                    return <MenuItem value={team.id}>{team.name}</MenuItem>
                })}
            </Select>
            <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px', mt: '20px' }} onClick={() => handleAddCode()} >Add Code</Button>
        </Box>}

        {operation === 'delete' && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FormControl sx={{ width: '300px', marginTop: '20px' }}>
                <InputLabel id="select-code">Select Code</InputLabel>
                <Select
                    labelId="select-code"
                    id="select-code"
                    value={selectedCode}
                    label="Select Code"
                    onChange={(e) => setSelectedCode(e.target.value)}
                    sx={{ width: '300px' }}
                >
                    {props.codeList.map((code) => {
                        return <MenuItem value={code.id}>{code.code}</MenuItem>
                    })}
                </Select>
                
            </FormControl>
            <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px' }} onClick={() => handleDeleteCode()} >Delete Code</Button>
        </Box>}

    </Box>
  );
};

export default EditCodes;
