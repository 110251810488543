import { AppBar, Box, Container, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const navigate = useNavigate();
    
    return (
      <AppBar position='static' sx={{ backgroundColor: '#000000' }}>
        <Container maxWidth='xl'>
            <Toolbar disableGutters>
                <img id='logo--no-name' alt='fantasy logo' src={process.env.PUBLIC_URL + '/logo.png'} style={{ width: '100px'  }} onClick={() => navigate('/')} />
                <Box sx={{ flexGrow: 1, textAlign: 'left' }} >
                    <Typography variant='h5' component='a' sx={{ color: '#ffffff', fontFamily: 'monospace', ml: '5px' }}> Cricket Saga </Typography>
                </Box>
                <MenuIcon onClick={handleOpenMenu} />
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    sx={{ marginTop: '45px' }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <MenuItem onClick={() => navigate('/')}>
                        Home
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/admin')}>
                        Admin
                    </MenuItem>
                </Menu>
            </Toolbar>
        </Container>
      </AppBar>
    );
}

export default Header;
