
import React from 'react';
import { 
    HashRouter as Router,
    Routes,
    Route
 } from 'react-router-dom';
import Home from './components/AppHome';
import AppAdmin from './components/AppAdmin';
import ModifyMyTeam from './components/ModifyMyTeam';
import WC2024 from './components/WC2024';

const RouteContainer = (props) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home totalScoreList={props.totalScoreList} />} />
        <Route path="/admin" element={<AppAdmin gameList={props.gameList} getGames={props.getGames} teamList={props.teamList} getTeams={props.getTeams} scoreList={props.scoreList} getScores={props.getScores} codeList={props.codeList} getCodes={props.getCodes} />} />
        <Route path='/modify-team' element={<ModifyMyTeam />} />
      </Routes>
    </Router>
  );
};

export default RouteContainer;
