import { Box, FormControlLabel, RadioGroup, Radio, Divider, TextField, Button, Snackbar, Alert, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const EditSchedule = (props) => {

    const [operation, setOperation] = React.useState(null);
    const [addDate, setAddDate] = React.useState(dayjs());
    const [addMatchNumber, setAddMatchNumber] = React.useState(null);
    const [addMatchTitle, setAddMatchTitle] = React.useState('');

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);


    const [addMatchNumberError, setAddMatchNumberError] = React.useState(false);
    const [addMatchTitleError, setAddMatchTitleError] = React.useState(false);

    const [selectedModifyGame, setSelectedModifyGame] = React.useState(null);


    const handleAddGame = async() => {
        if (isNaN(addMatchNumber) || addMatchNumber === '' || addMatchNumber < 0 || addMatchNumber === null) {
            setAddMatchNumberError(true);
        }
        else {
            setAddMatchNumberError(false);
        }

        if (addMatchTitle === '') {
            setAddMatchTitleError(true);
        }
        else {
            setAddMatchTitleError(false);
        }

        if (!isNaN(addMatchNumber) && addMatchNumber !== '' && addMatchNumber > 0 && addMatchTitle !== '' && addDate !== null) {
            try {
                const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com//api/add-game', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        match_number: addMatchNumber,
                        match_title: addMatchTitle,
                        match_date: addDate
                    })
                });
                if (response.status === 200) {
                    setOpenSuccess(true);
                    setAddMatchNumber(null);
                    setAddMatchTitle('');
                    setAddDate(dayjs());
                    props.getGames();
                }
                else {
                    setOpenError(true);
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    }

    const handleGameSelect = (e) => {
        const game = props.gameList.filter(game => game.id === e.target.value);
        setSelectedModifyGame(game[0]);
    }

    const handleDeleteGame = async() => {
        try {
            const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/delete-game', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    game_id: selectedModifyGame.id
                })
            });
            if (response.status === 200) {
                setSelectedModifyGame(null);
                setOpenSuccess(true);
            }
            props.getGames();
        }
        catch (err) {
            console.log(err);
            setOpenError(true);
        }
    }

    return (
        <Box sx={{ justifyContent: "center", textAlign: 'center' }}>

            <RadioGroup onChange={(e) => setOperation(e.target.value)} value={operation} row sx={{ justifyContent: 'center' }}>
                <FormControlLabel value="add" control={<Radio />} label="Add New Game" />
                <FormControlLabel value="modify" control={<Radio />} label="Delete Existing Game" />
            </RadioGroup>

            {operation && <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />}

            {operation === 'add' && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField 
                        type='text' 
                        label='Match Number' 
                        sx={{ marginRight: '20px' }} 
                        value={addMatchNumber} 
                        onChange={(e) => setAddMatchNumber(e.target.value)}
                        error={addMatchNumberError}
                        helperText={addMatchNumberError ? 'Match Number must be a positive number' : ''}    
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Basic date picker" 
                            value={addDate} 
                            onChange={(newValue) => setAddDate(newValue)} 
                            sx={{ width: '180px' }}
                        />
                    </LocalizationProvider><br />
                </Box>
                <TextField 
                    type='text' 
                    label='Match Title' 
                    sx={{ marginRight: '20px', width: '300px', marginTop: '20px' }} 
                    value={addMatchTitle} 
                    onChange={(e) => setAddMatchTitle(e.target.value)}
                    error={addMatchTitleError}
                    helperText={addMatchTitleError ? 'Match Title must be provided' : ''}
                /> <br />
                <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px' }} onClick={() =>handleAddGame()}>Add Game</Button>
            </Box>}

            {operation === 'modify' && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FormControl variant='filled' sx={{ width: '300px', marginBottom: '20px' }}>
                    <InputLabel id="select-game-label">Select Game</InputLabel>
                    <Select 
                        sx={{ width: '300px', marginBottom: '20px' }}
                        value={selectedModifyGame ? selectedModifyGame.id : null}
                        onChange={handleGameSelect}
                    >
                        {props.gameList.map((game) => (
                            <MenuItem key={game.id} value={game.id}>{game.game_number} - {game.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedModifyGame && <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px' }} onClick={handleDeleteGame} >Delete Game</Button>} <br />
            </Box>}

            <Snackbar open={openSuccess} autoHideDuration={6000} onClose={() => setOpenSuccess(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setOpenSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Success!
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={() => setOpenError(false)} severity="error" sx={{ width: '100%' }}>
                    Error Occurred!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EditSchedule;
