import { Box } from '@mui/material';
import './App.css';
import RouteContainer from './RouteContainer';
import React, { useEffect } from 'react';

function App() {
  const [gameList, setGameList] = React.useState(null);
  const [teamList, setTeamList] = React.useState(null);
  const [scoreList, setScoreList] = React.useState(null);
  const [totalScoreList, setTotalScoreList] = React.useState(null);
  const [codeList, setCodeList] = React.useState(null);

  const getCodes = async() => {
    try {
        const response = await fetch('https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-codes');
        const data = await response.json();
        setCodeList(data);
        console.log(data);
    }
    catch (error) {
        console.log(error);
    }
  }

  const getGames = async() => {
    try {
        const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-games');
        const data = await response.json();
        setGameList(data);
    }
    catch (err) {
        console.log(err);
    }
  }

  const getTeams = async() => {
    try {
        const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-teams');
        const data = await response.json();
        setTeamList(data);
    }
    catch (err) {
        console.log(err);
    }
  }

  const getScores = async() => {
    try {
        const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-scores');
        const data = await response.json();
        setScoreList(data);
    }
    catch (err) {
        console.log(err);
    }
  }

  const getTeamTotalScores = async() => {
    try {
        const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-team-name-and-total-scores');
        const data = await response.json();
        setTotalScoreList(data);
        console.log(data);
    }
    catch (err) {
        console.log(err);
    }
  }

  useEffect(() => {
    getGames();
    getTeams();
    getScores();
    getTeamTotalScores();
    getCodes();
  }, []);


  return (
    <Box className="App">
      <RouteContainer gameList={gameList} getGames={getGames} teamList={teamList} getTeams={getTeams} scoreList={scoreList} getScores={getScores} totalScoreList={totalScoreList} codeList={codeList} getCodes={getCodes} />
    </Box>
  );
}

export default App;
