import React from 'react';
import ModifyTeam from './AdminTiles/ModifyTeam';
import { Box, Button, TextField, Typography } from '@mui/material';
import Header from './Header/Header';

const ModifyMyTeam = () => {
    const [teamCode, setTeamCode] = React.useState('');
    const [teamCodeError, setTeamCodeError] = React.useState(false);
    const [selectedModifyTeam, setSelectedModifyTeam] = React.useState(null);
    const [modifyTeamName, setModifyTeamName] = React.useState('');
    const [modifyTeamNameError, setModifyTeamNameError] = React.useState(false);
    const [editColor1, setEditColor1] = React.useState('#000000');
    const [editColor2, setEditColor2] = React.useState('#000000');
    const [logoLink, setLogoLink] = React.useState('');

    const handleSubmit = async() => {
        if (teamCode === '') {
            setTeamCodeError(true);
        }
        else {
            setTeamCodeError(false);
        }

        if (teamCode !== '') {
            try {
                const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-team-from-code', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        code: teamCode
                    })
                });
                if (response.status === 200) {
                    const data = await response.json();
                    setSelectedModifyTeam(data);
                    setModifyTeamName(data.name);
                    setEditColor1(data.color1);
                    setEditColor2(data.color2);
                    setLogoLink(data.logo_link);
                }
                else {
                    setTeamCodeError(true);
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    }

    const handleModifyTeam = async() => {
        if (modifyTeamName === '') {
            setModifyTeamNameError(true);
        }
        else {
            setModifyTeamNameError(false);
        }
  
        if (modifyTeamName !== '') {
            try {
                const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/modify-team', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        team_id: selectedModifyTeam.id,
                        team_name: modifyTeamName,
                        color1: editColor1,
                        color2: editColor2,
                        logo: logoLink
                    })
                });
                if (response.status === 200) {
                    setModifyTeamName('');
                    setEditColor1('#000000');
                    setEditColor2('#000000');
                    setSelectedModifyTeam(null);
                    setLogoLink('');
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    }

  return (
    <Box >
    <Header />
      <h1>Modify My Team</h1>
      {!selectedModifyTeam ?
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px', margin: 'auto' }}>
        <Typography variant='h6'>Enter your team code</Typography>
        <TextField type='text' label='Team Code'
            value={teamCode}
            onChange={(e) => setTeamCode(e.target.value)}
            error={teamCodeError}
            helperText={teamCodeError ? 'Team Code invalid' : ''}
        /><br />
        <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px' }} onClick={() => handleSubmit()}>Submit</Button>
      </Box> :
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ModifyTeam logoLink={logoLink} setLogoLink={setLogoLink} selectedModifyTeam={selectedModifyTeam} modifyTeamName={modifyTeamName} handleModifyTeam={handleModifyTeam} setModifyTeamName={setModifyTeamName} modifyTeamNameError={modifyTeamNameError} setModifyTeamNameError={setModifyTeamNameError} editColor1={editColor1} setEditColor1={setEditColor1} editColor2={editColor2} setEditColor2={setEditColor2} />
      </Box>}
    </Box>
  );
};

export default ModifyMyTeam;
