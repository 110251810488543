import React from 'react';
import { Box, FormControlLabel, RadioGroup, Radio, Divider, TextField, Button, Snackbar, Alert, Select, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { HexColorPicker } from "react-colorful";import ModifyTeam from './ModifyTeam';
;


const EditTeams = (props) => {
  
  const [operation, setOperation] = React.useState(null);
  const [addTeamName, setAddTeamName] = React.useState('');
  const [addTeamNameError, setAddTeamNameError] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const [selectedModifyTeam, setSelectedModifyTeam] = React.useState(null);
  const [modifyTeamName, setModifyTeamName] = React.useState('');
  const [modifyTeamNameError, setModifyTeamNameError] = React.useState(false);

  const [color1, setColor1] = React.useState('#000000');
  const [color2, setColor2] = React.useState('#000000');

  const [editColor1, setEditColor1] = React.useState('#000000');
  const [editColor2, setEditColor2] = React.useState('#000000');
  const [logoLink, setLogoLink] = React.useState('');


  const handleAddTeam = async() => {
      if (addTeamName === '') {
          setAddTeamNameError(true);
      }
      else {
          setAddTeamNameError(false);
      }

      if (addTeamName !== '') {
          try {
              const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/add-team', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                      team_name: addTeamName,
                        color1: color1,
                        color2: color2
                  })
              });
              if (response.status === 200) {
                  setOpenSuccess(true);
                  setAddTeamName('');
                  props.getTeams();
              }
              else {
                  setOpenError(true);
              }
          }
          catch (err) {
              console.log(err);
          }
      }
  }

  const handleModifyTeam = async() => {
      if (modifyTeamName === '') {
          setModifyTeamNameError(true);
      }
      else {
          setModifyTeamNameError(false);
      }

      if (modifyTeamName !== '') {
          try {
              const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/modify-team', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                      team_id: selectedModifyTeam.id,
                      team_name: modifyTeamName,
                    color1: editColor1,
                    color2: editColor2,
                    logo: logoLink
                  })
              });
              if (response.status === 200) {
                  setOpenSuccess(true);
                  setModifyTeamName('');
                  setSelectedModifyTeam(null);
                  props.getTeams();
                  setLogoLink('');
              }
              else {
                  setOpenError(true);
              }
          }
          catch (err) {
              console.log(err);
          }
      }
  }

  const handleTeamSelect = (e) => {
      const team = props.teamList.filter(team => team.id === e.target.value);
      setSelectedModifyTeam(team[0]);
      setModifyTeamName(team[0].name);
        setEditColor1(team[0].color1);
        setEditColor2(team[0].color2);
        setLogoLink(team[0].logo_link);
  }

  const handleDeleteTeam = async() => {
      try {
          const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/delete-team', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  team_id: selectedModifyTeam.id
              })
          });
          if (response.status === 200) {
              setSelectedModifyTeam(null);
              setOpenSuccess(true);
              props.getTeams();
          }
      }
      catch (err) {
          console.log(err);
          setOpenError(true);
      }
  }

  return (
    <Box sx={{ justifyContent: "center", textAlign: 'center' }}>
       <RadioGroup onChange={(e) => setOperation(e.target.value)} value={operation} row sx={{ justifyContent: 'center' }}>
            <FormControlLabel value="add" control={<Radio />} label="Add New Team" />
            <FormControlLabel value="modify" control={<Radio />} label="Modify Existing Game" />
        </RadioGroup>

        {operation && <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />}

        {operation === 'add' && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextField 
                    type='text' 
                    label='Team Name' 
                    sx={{ marginRight: '20px' }} 
                    value={addTeamName} 
                    onChange={(e) => setAddTeamName(e.target.value)}
                    error={addTeamNameError}
                    helperText={addTeamNameError ? 'Team Name invalid' : ''}    
                />
            </Box>
            <br />
            <Typography variant='h6'>Team Colors</Typography>
            <br /> <br />
            <Box sx={{ width: '100px', height: '30px', backgroundColor: `${color1}`, color:`${color2}`, fontWeight: 'bold' }}> {addTeamName === '' ? 'Team Name' : addTeamName } </Box>
            <Typography variant='subtitle1'>Primary Color</Typography>
            <HexColorPicker color={color1} onChange={setColor1} />
            <TextField sx={{ marginTop: '20px' }} type='text' label='Hex Code' value={color1} onChange={(e) => setColor1(e.target.value)} />
            <br />
            <Typography variant='subtitle1'>Secondary Color</Typography>
            <HexColorPicker color={color2} onChange={setColor2} />
            <TextField sx={{ marginTop: '20px' }} type='text' label='Hex Code' value={color2} onChange={(e) => setColor2(e.target.value)} />
            <br />
            <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px' }} onClick={() =>handleAddTeam()}>Add Team</Button>
        </Box>}

        {operation === 'modify' && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FormControl variant='filled' sx={{ width: '300px', marginBottom: '20px' }}>
                <InputLabel id="select-team-label">Select Team</InputLabel>
                <Select 
                    sx={{ width: '300px', marginBottom: '20px' }}
                    value={selectedModifyTeam ? selectedModifyTeam.id : null}
                    onChange={handleTeamSelect}
                >
                    {props.teamList.map((team) => (
                        <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ModifyTeam showDelete logoLink={logoLink} setLogoLink={setLogoLink} selectedModifyTeam={selectedModifyTeam} modifyTeamName={modifyTeamName} setModifyTeamName={setModifyTeamName} modifyTeamNameError={modifyTeamNameError} editColor1={editColor1} editColor2={editColor2} setEditColor1={setEditColor1} setEditColor2={setEditColor2} handleModifyTeam={handleModifyTeam} handleDeleteTeam={handleDeleteTeam} />
        </Box>}

        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={() => setOpenSuccess(false)}>
            <Alert onClose={() => setOpenSuccess(false)} severity="success" sx={{ width: '100%' }}>
                Operation Successful!
            </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)}>
            <Alert onClose={() => setOpenError(false)} severity="error" sx={{ width: '100%' }}>
                Operation Failed!
            </Alert>
        </Snackbar>
    </Box>
    
  );
};

export default EditTeams;
