import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { HexColorPicker } from "react-colorful";
import { useNavigate } from 'react-router-dom';
import UpdateLogo from '../UpdateLogo';
;

const ModifyTeam = (props) => {
  return (<>
    {props.selectedModifyTeam && <>
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <TextField 
            type='text' 
            label='Team Name' 
            sx={{ marginRight: '20px' }} 
            value={props.modifyTeamName} 
            onChange={(e) => props.setModifyTeamName(e.target.value)}
            error={props.modifyTeamNameError}
            helperText={props.modifyTeamNameError ? 'Team Name invalid' : ''}    
        />
    </Box>

    <Typography variant='h6'>Team Colors</Typography>
    <br /> <br />
    <Box sx={{ width: '100px', height: '30px', backgroundColor: `${props.editColor1}`, color:`${props.editColor2}`, fontWeight: 'bold' }}> {props.modifyTeamName === '' ? 'Team Name' : props.modifyTeamName } </Box>
    <Typography variant='subtitle1'>Primary Color</Typography>
    <HexColorPicker color={props.editColor1} onChange={props.setEditColor1} />
    <TextField sx={{ marginTop: '20px' }} type='text' label='Hex Code' value={props.editColor1} onChange={(e) => props.setEditColor1(e.target.value)} />
    <br />
    <Typography variant='subtitle1'>Secondary Color</Typography>
    <HexColorPicker color={props.editColor2} onChange={props.setEditColor2} />
    <TextField sx={{ marginTop: '20px' }} type='text' label='Hex Code' value={props.editColor2} onChange={(e) => props.setEditColor2(e.target.value)} />
    <br />
    <br />
    <UpdateLogo team_id={props.selectedModifyTeam.id} />
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px' }} onClick={() =>props.handleModifyTeam()}>Modify Team</Button> <br />
        {props.showDelete && <Button variant='contained' sx={{  backgroundColor: '#000000', width: '150px', marginLeft: '20px' }} onClick={() => props.handleDeleteTeam()}>Delete Team</Button>}
    </Box>
    <br />
    </>}
    </>);
};

export default ModifyTeam;
