import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Dialog, Divider, Select, Tab, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import Logo from './Logo';

const Home = (props) => {
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [scoreList, setScoreList] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [topImage, setTopImage] = React.useState(null);
  let ARank = 0;
  let BRank = 0;
  let CRank = 0;
  let DRank = 0;

  function addToRankA() {
    ARank++;
    return ARank;
  }
  function addToRankB() {
    BRank++;
    return BRank;
  }
  function addToRankC() {
    CRank++;
    return CRank;
  }
  function addToRankD() {
    DRank++;
    return DRank;
  }

  const handleExpandChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }
  async function getIndividualScores(id){
    const response = await fetch('https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-team-results', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        team_id: id
      })
    });
    const data = await response.json();
    setScoreList(data);
    console.log(data);
  };

  function handleRowClick(id, name){
    getIndividualScores(id);
    setName(name);
    setOpen(true);
  }
  async function getTopImage(){
    const response = await fetch('https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/get-top-image', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    const data = await response.json();
    setTopImage(data);
  };

  const navigate = useNavigate();
  return (
    <Box>
      <Header />
      
      <Box sx={{  margin: '10px', marginTop: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" sx={{ backgroundColor: 'black', display: 'flex' }} onClick={() => navigate('/modify-team')}>My Team</Button>
          <Box sx={{ flexGrow: 1}} />
        </Box>
      <Typography sx={{ textAlign: 'left', mb: '20px' }}>Modify you teams appearence here!</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '10px' }}>
        {topImage ? <img style={{ width: '100%', objectFit: 'contain' }} alt='top' src={topImage} /> : <></> }
      </Box>

      {props.totalScoreList && <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'black' }}>
              <TableCell align="center" sx={{ color: 'white' }} >Rank</TableCell>
              <TableCell align="center" sx={{ color: 'white' }} >Team</TableCell>
              <TableCell align="center" sx={{ color: 'white' }} >Score</TableCell>
            </TableRow>
          </TableHead>
          <Divider sx={{ margin: '15px' }} />
          <TableBody>

            <TableRow sx={{ backgroundColor: 'black' }}>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
              <TableCell align="center" sx={{ color: 'white' }} >Group A</TableCell>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
            </TableRow>
            {props.totalScoreList.map((row, index) => (<>
              {row.group === 'A' && <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}> {addToRankA()} </TableCell>
                <TableCell align="center" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>
                  <Logo team_id={row.id} />
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>{row.total_score}</TableCell>
              </TableRow>}
            </>))}

            <Divider sx={{ margin: '15px' }} />
            <TableRow sx={{ backgroundColor: 'black' }}>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
              <TableCell align="center" sx={{ color: 'white' }} >Group B</TableCell>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
            </TableRow>
            {props.totalScoreList.map((row, index) => (<>
              {row.group === 'B' && <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}> {addToRankB()} </TableCell>
                <TableCell align="center" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>
                  <Logo team_id={row.id} />
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>{row.total_score}</TableCell>
              </TableRow>}
            </>))}

            <Divider sx={{ margin: '15px' }} />
            <TableRow sx={{ backgroundColor: 'black' }}>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
              <TableCell align="center" sx={{ color: 'white' }} >Group C</TableCell>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
            </TableRow>
            {props.totalScoreList.map((row, index) => (<>
              {row.group === 'C' && <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}> {addToRankC()} </TableCell>
                <TableCell align="center" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>
                  <Logo team_id={row.id} />
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>{row.total_score}</TableCell>
              </TableRow>}
            </>))}

            <Divider sx={{ margin: '15px' }} />
            <TableRow sx={{ backgroundColor: 'black' }}>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
              <TableCell align="center" sx={{ color: 'white' }} >Group D</TableCell>
              <TableCell align="center" sx={{ color: 'white' }} ></TableCell>
            </TableRow>
            {props.totalScoreList.map((row, index) => (<>
              {row.group === 'D' && <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}> {addToRankD()} </TableCell>
                <TableCell align="center" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>
                  <Logo team_id={row.id} />
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: `${row.color1}`, color: `${row.color2}`, fontWeight:'bold' }} onClick={() => handleRowClick(row.id, row.name)}>{row.total_score}</TableCell>
              </TableRow>}
            </>))}



          </TableBody>
        </Table>
      </TableContainer>}
      </Box>
      <Divider sx={{ margin: '15px' }} />
      <Accordion sx={{  margin: '10px', marginTop: '20px' }} expanded={expanded === 'rules'} onChange={handleExpandChange('rules')}>
        <AccordionSummary id='admin-titles' expandIcon={<ExpandMoreIcon />} sx={ expanded !== 'rules' ? { backgroundColor: '#ffffff', color: '#000000' } : { backgroundColor: '#000000', color: '#ffffff'}}>
          <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>Scoring</Typography>
          <Typography sx={{ color: 'grey' }}>Learn about scoring</Typography>
        </AccordionSummary>
        <AccordionDetails>
      <Paper sx={{  margin: '10px', marginTop: '20px' }}>
        <Typography variant='h4' sx={{ textAlign: 'center' }}>Scoring</Typography> <br />
        <Typography variant='h6'>Each team will receive points based on their placement in each game.</Typography> <br />
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'black' }}>
              <TableCell align="center" sx={{ color: 'white' }} >Placement</TableCell>
              <TableCell align="center" sx={{ color: 'white' }} >Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>1st</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>25</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>2nd</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>18</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>3rd</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>15</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>4th</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>12</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>5th</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>10</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>6th</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>8</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>7th</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>6</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>8th</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>4</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>9th</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>2</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>10th</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>1</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>11th +</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold' }}>0</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" sx={{ fontWeight:'bold' }}>No Participation</TableCell>
              <TableCell align="center" sx={{ fontWeight:'bold', color: 'red' }}>-5</TableCell>
            </TableRow>
          </TableBody>
        </Table><br /> <br />
      </Paper>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{  margin: '10px', marginTop: '20px' }} expanded={expanded === 'playoffs'} onChange={handleExpandChange('playoffs')}>
        <AccordionSummary id='admin-titles' expandIcon={<ExpandMoreIcon />} sx={ expanded !== 'playoffs' ? { backgroundColor: '#ffffff', color: '#000000' } : { backgroundColor: '#000000', color: '#ffffff'}}>
          <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>Play-Offs</Typography>
          <Typography sx={{ color: 'grey' }}>Learn how playoffs work</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Paper sx={{  margin: '10px', marginTop: '20px' }}>
          {/* <Typography variant='h4' sx={{ textAlign: 'center' }}>Play Offs</Typography> <br />
          <Typography >The <b>top 2 teams</b> will advance to the SEMIFINALS (Q2).</Typography> <br />

          <Typography variant='h5' sx={{ textAlign: 'center' }}>Qualifier 1</Typography>
          <Typography sx={{ textAlign: 'center' }}><a style={{ color: 'green', fontWeight: 'bold' }}>3 teams </a> from ranks (<b>3</b>, <b>4</b>, <b>5</b> and <b>6</b> qualifies) <a style={{ color: 'red', fontWeight: 'bold' }}>(1 team Eliminated)</a></Typography>
          <Typography sx={{ textAlign: 'center' }}><a style={{ color: 'green', fontWeight: 'bold' }}>1 team </a> from ranks (<b>7</b>, <b>8</b>, <b>9</b> and <b>10</b> qualifies) <a style={{ color: 'red', fontWeight: 'bold' }}>(3 teams Eliminated)</a></Typography> <br />

          <Typography variant='h5' sx={{ textAlign: 'center' }}>Eliminator</Typography>
          <Typography sx={{ textAlign: 'center' }}><a style={{ color: 'green', fontWeight: 'bold' }}>2 teams </a> from <b>Q1</b> qualifies <a style={{ color: 'red', fontWeight: 'bold' }}>(2 teams Eliminated)</a></Typography> <br />

          <Typography variant='h5' sx={{ textAlign: 'center' }}>Qualifier 2</Typography>
          <Typography sx={{ textAlign: 'center' }}><a style={{ color: 'green', fontWeight: 'bold' }}>2 teams </a> from ranks (<b>1</b>, <b>2</b>) and the <b>Eliminator</b>  qualifies <a style={{ color: 'red', fontWeight: 'bold' }}>(2 teams Eliminated)</a></Typography> <br />

          <Typography variant='h5' sx={{ textAlign: 'center' }}>Finals</Typography>
          <Typography variant='h6' sx={{ textAlign: 'center' }}><a style={{ color: 'green', fontWeight: 'bold' }}>One Winner</a> from the 2 teams from Q2</Typography> <br /> <br />

          <img style={{ width: '100%', objectFit: 'contain' }} alt='playoff' src={process.env.PUBLIC_URL + '/Playoffs.png'} /> */}
 <br />
          <Typography sx={{ textAlign: 'center' }}>
            Top 2 teams from each group will advance to the playoffs (Super 8). <br /> <br />
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            The top 2 teams from each group will be seeded into 2 groups. <br />
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
              <Typography variant='h5'>Group 1</Typography>
              <Typography>A1</Typography>
              <Typography>B2</Typography>
              <Typography>C1</Typography>
              <Typography>D2</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
              <Typography variant='h5'>Group 2</Typography>
              <Typography>A2</Typography>
              <Typography>B1</Typography>
              <Typography>C2</Typography>
              <Typography>D1</Typography>
            </Box>
          </Box>
          <Typography sx={{ textAlign: 'center' }}>
            The top 2 teams from each group will advance to the semi-finals. <br />
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
              <Typography variant='h5'>Semi-Final 1</Typography>
              <Typography>Winner Group 1</Typography>
              <Typography>Runner-up Group 2</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
              <Typography variant='h5'>Semi-Final 2</Typography>
              <Typography>Winner Group 2</Typography>
              <Typography>Runner-up Group 1</Typography>
            </Box>
          </Box>
          <br />
          <Typography sx={{ textAlign: 'center' }}>
            The winners of the semi-finals will play the finals. <br />
          </Typography>
          <br />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
              <Typography variant='h5'>Finals</Typography>
              <Typography>Winner Semi-Final 1</Typography>
              <Typography>Winner Semi-Final 2</Typography>
            </Box>
          </Box>
          
        </Paper>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{  margin: '10px', marginTop: '20px' }} expanded={expanded === 'previous-winners'} onChange={handleExpandChange('previous-winners')}>
        <AccordionSummary id='admin-titles' expandIcon={<ExpandMoreIcon />} sx={ expanded !== 'previous-winners' ? { backgroundColor: '#ffffff', color: '#000000' } : { backgroundColor: '#000000', color: '#ffffff'}}>
          <Typography sx={{ width: '33%', flexShrink: 0, textAlign: 'left' }}>Hall of Fame</Typography>
          <Typography sx={{ color: 'grey' }}>View previous winners</Typography>
        </AccordionSummary>
        <AccordionDetails>
      <Paper sx={{  margin: '10px', marginTop: '20px' }}>
        <Typography variant='h4' sx={{ textAlign: 'center' }}>Previous Winners and Runnerups</Typography> <br /> <br />
        <Divider sx={{ margin: '5px' }} />

        <Typography variant='h4' sx={{ textAlign: 'center' }}>IPL 2024</Typography>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>Winner: Jelly</Typography>  
        <Typography variant='h6' sx={{ textAlign: 'center' }}>Runner-up: Chaman4507</Typography> <br />
        <Divider sx={{ margin: '5px' }} />

        <Typography variant='h4' sx={{ textAlign: 'center' }}>IPL 2023</Typography>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>Winner: Sahildoki</Typography>  
        <Typography variant='h6' sx={{ textAlign: 'center' }}>Runner-up: Megh29</Typography> <br />
        <Divider sx={{ margin: '5px' }} />

        <Typography variant='h4' sx={{ textAlign: 'center' }}>IPL 2022</Typography>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>Winner: Jailu's 11</Typography>
        <Typography variant='h6' sx={{ textAlign: 'center' }}>Runner-up: Sahil mmhmm</Typography> <br />
        <Divider sx={{ margin: '5px' }} />

      </Paper>
        </AccordionDetails>
      </Accordion>


    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box>
        <Typography variant='h4'>{name}</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'black' }}>
                <TableCell align="center" sx={{ color: 'white' }} >No.</TableCell>
                <TableCell align="center" sx={{ color: 'white' }} >Match</TableCell>
                <TableCell align="center" sx={{ color: 'white' }} >Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scoreList && scoreList.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" sx={{ fontWeight:'bold' }}>{row.game_number}</TableCell>
                  <TableCell align="center" sx={{ fontWeight:'bold' }}>{row.title} </TableCell>
                  <TableCell align="center" sx={{ fontWeight:'bold' }}>{row.score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Dialog>


    </Box>
  );
};

export default Home;
