import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormControl, InputLabel, Select,MenuItem, TextField } from '@mui/material';


const EditScore = (props) => {
  const [selectedGame, setSelectedGame] = React.useState(null);


  const handleGameSelect = (e) => {
    const game = props.gameList.find(game => game.id === e.target.value);
    setSelectedGame(game);
  }

  const getTeamGameScore = (team_id) => {
    const score = props.scoreList.find(score => score.game_id === selectedGame.id && score.team_id === team_id);
    return score ? score.score : 0;
  }

  const handleScoreChange = async(e, team_id) => {
    if (e.target.value === '') {
      return;
    }
    try {
      const response = await fetch(' https://fantasyipl-09f3ac5f0dec.herokuapp.com/api/edit-score', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          game_id: selectedGame.id,
          team_id: team_id,
          score: e.target.value
        })
      });
      if (response.status === 200) {
        props.getScores();
      }
    }
    catch (err) {
      console.log(err);
    }
  }


  return (
    <Box sx={{ justifyContent: "center", textAlign: 'center' }}>
      <FormControl variant='filled' sx={{ width: '300px', marginBottom: '20px' }}>
          <InputLabel id="select-game-label">Select Game</InputLabel>
          <Select 
              sx={{ width: '300px', marginBottom: '20px' }}
              value={selectedGame ? selectedGame.id : null}
              onChange={handleGameSelect}
          >
              {props.gameList.map((game) => (
                  <MenuItem key={game.id} value={game.id}>{game.game_number} - {game.title}</MenuItem>
              ))}
          </Select>
      </FormControl>

      {selectedGame && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {props.teamList.map((team) => (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
            <Typography sx={{ width: '100px', textAlign: 'left' }}>{team.name}</Typography>
            <TextField
              value={getTeamGameScore(team.id)}
              onChange={(e) => handleScoreChange(e, team.id)}
              id="outlined-password-input"
              label="Score"
              type="number"
              sx={{ width: '100px' }}
            />
          </Box>
        ))}
      </Box>}

    </Box>
  );
};

export default EditScore;
